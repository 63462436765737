<template>
  <div class="menuManage ">
    <div class="top box">
      <ul>
        <li><span>菜单名称:</span><p><el-input v-model="search_param.menu_title" size="mini" placeholder="请输入菜单名称"></el-input></p></li>
        <li><span>菜单状态:</span>
          <p>
            <el-select v-model="search_param.visible" size="mini" placeholder="请选择菜单状态" clearable>
              <el-option
                v-for="item in option_zt"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </p>
          <!-- <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input> -->
         
        </li>
        <li>  <el-button type="primary" size="mini" @click="search_tableData_now">搜索</el-button> </li>

      </ul>
    </div>
    <div class="body box">
      <div class="btn-box">
        <el-button type="primary" size="mini" @click="open_addMenu_dialog()" :disabled="btn_add_disabled">新增</el-button> 
        <el-button type="warning" size="mini" @click="open_editMenu_dialog()" :disabled="btn_edit_disabled">编辑</el-button> 
      </div>
      <div >
        <el-table
          ref="tableMean"
          :data="tableData_now"
          stripe
          row-key="menu_id"
          size="small"
          default-expand-all
          v-loading="loading_table"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          @selection-change="selectionchange"
          @select-all="selectAllFun"
          >
          <el-table-column
            align="center"
            type="selection"
            width="55">
          </el-table-column>
     
          <el-table-column
            prop="menu_title"
            label="菜单名称"
            align="left"
            :width="flexColumnWidth(260)"
            >
          </el-table-column>
          <el-table-column
            prop="menu_id"
            label="菜单id"
            align="center"
            :width="flexColumnWidth(100)"
            >
          </el-table-column>
          <el-table-column
            align="center"
            prop="priority"
            label="显示顺序"
            :width="flexColumnWidth(100)">
          </el-table-column>
          <el-table-column
            align="center"
            prop="web_path"
            :width="flexColumnWidth(260)"
            label="请求地址">
          </el-table-column>
          <el-table-column
            align="center"
            prop="menu_type"
            label="类型"
            :width="flexColumnWidth(100)"
            >
            <template slot-scope="scope">
              <div class="col-box">
                <span class="type-box" style="backgroundColor:#169BD5" v-if="scope.row.menu_type == 1001"> 目录</span>
                <span class="type-box" style="backgroundColor:#70B603" v-if="scope.row.menu_type == 1002"> 菜单</span>
                <span class="type-box" style="backgroundColor:#FACD91" v-if="scope.row.menu_type == 1003"> 按钮</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="visible"
            label="状态"
            :width="flexColumnWidth(100)"
            >
            <template slot-scope="scope">
              <div class="col-box">
                <span class="show-box" style="backgroundColor:#1BB394" v-if="scope.row.visible"> 显示</span>
                <span class="show-box" style="backgroundColor:#ED5564" v-else> 隐藏</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="permission_flag"
            :width="flexColumnWidth(130)"
            label="权限标识">
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
           >
           <template slot-scope="scope">
            <div class="caozuo">
              <el-button type="primary" size="mini" @click="open_addMenu_dialog(scope.row)">新增</el-button> 
              <el-button type="warning" size="mini" @click="open_editMenu_dialog(scope.row)">编辑</el-button> 
              <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定删除该菜单吗？"
                @confirm="del_system_menu(scope.row)"
              >
                <el-button slot="reference" type="danger" style="marginLeft:10px" size="mini">删除</el-button>
              </el-popconfirm>
            </div>
          </template>
          </el-table-column>
         
        </el-table>
      </div>
    </div>
    <el-dialog
      :title="dialogType=='ADD'?'添加菜单':'修改菜单'"
      :visible.sync="dialogVisible"
      width="50%"
      >
      <div class="dialog-body">
        <ul>

          <li><span>上级菜单:</span><p>
            <!-- <el-input v-model="menuData.parent_menu_id" size="mini" placeholder="请输入内容"></el-input> -->
            <el-select
              v-model="menuData.parent_menu_id"
              filterable
              remote
              placeholder="请输入上级菜单"
              :remote-method="remoteMethod"
              :disabled="dialogType=='EDIT'"
              size="mini"
              clearable
              >
              <el-option
                v-for="item in parent_menu_list"
                :key="item.menu_id"
                :label="item.menu_title"
                :value="item.menu_id">
              </el-option>
            </el-select>
          </p></li>
          <li><span><i>*</i>菜单类型:</span><p>
            <el-radio v-model="menuData.menu_type" :label="1001">目录</el-radio>
            <el-radio v-model="menuData.menu_type" :label="1002">菜单</el-radio>
            <el-radio v-model="menuData.menu_type" :label="1003">按钮</el-radio>
          </p></li>
          <li><span><i>*</i>菜单名称:</span><p><el-input v-model="menuData.menu_title" size="mini" placeholder="请输入菜单名称"></el-input></p></li>
          <li><span><i>*</i>请求地址:</span><p><el-input v-model="menuData.web_path" size="mini" placeholder="无地址时请填#"></el-input></p></li>
          <li><span>权限标识:</span><p><el-input v-model="menuData.permission_flag" size="mini" placeholder="请输入权限标识"></el-input></p></li>
          <li><span>显示顺序:</span><p><el-input v-model="menuData.priority" size="mini" placeholder="请输入显示顺序"></el-input></p></li>
          <li><span>菜单状态:</span><p>
            <el-radio v-model="menuData.visible" :label="true">显示</el-radio>
            <el-radio v-model="menuData.visible" :label="false">隐藏</el-radio>
          </p></li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogType=='ADD'?add_system_menu():put_system_menu()" size="small">确 定</el-button>
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/manage.js'
import manageMixins from './components/manageMixins.js';
import {flexColumnWidth} from '@/assets/js/adaption.js'

import { runInThisContext } from 'vm';
export default {
  mixins: [manageMixins],
  data() {
    return {
      tableData: [
      ],
      tableData_now: [],
      option_zt: [{
        value: true,
        label: '显示'
      },{
        value: false,
        label: '隐藏'
      }],
      search_param: {
        menu_title: '',
        visible: '',
      },
      input: '',
      dialogVisible: false,
      dialogType: 'ADD',
      menuData: {
        parent_menu_id: '',
        menu_title: '',
        menu_type: '',
        web_path: '',
        permission_flag: '',
        priority: '',
        visible: true,
      },
      loading_table: false,
      parent_menu_list: [],
      multipleSelection: [],
      btn_add_disabled: false,
      btn_edit_disabled: true,
    }
  },
  created(){
    this.get_system_menu();
    this.remoteMethod('');
  },
  methods: {
    // 宽度
    flexColumnWidth(width,minWidth) {
      return flexColumnWidth(width,minWidth)
    },
    // 打开新增菜单弹窗
    open_addMenu_dialog(row=this.dialog_menuData) {
      this.menuData= {
        parent_menu_id: '',
        menu_title: '',
        menu_type: '',
        web_path: '',
        permission_flag: '',
        priority: '',
        visible: true,
      }
      if(row){
        this.menuData.parent_menu_id = row.menu_id;
        this.menuData.menu_type = row.menu_type==1001?1002:1003
      }
      this.dialogType = 'ADD';
      this.dialogVisible = true;
    },
    // 打开编辑增菜单弹窗
    open_editMenu_dialog(row=this.dialog_menuData) {
      this.menuData = {
        menu_id: row.menu_id,
        parent_menu_id: row.parent_menu_id,
        menu_title: row.menu_title,
        menu_type: row.menu_type,
        web_path: row.web_path,
        permission_flag: row.permission_flag,
        priority: row.priority,
        visible: row.visible,
      }

      this.dialogType = 'EDIT';
      this.dialogVisible = true;
    },
    // 全选
    selectAllFun(selection) {
      let that = this
      function setAll(list) {
        list.forEach(temp => {
          that.$refs.tableMean.toggleRowSelection(temp,true)
          if(temp.children){
            setAll(temp.children)
          }
        })
      }
      if(selection.length===this.tableData_now.length){
        setAll(selection)
      }else {
        this.$refs.tableMean.clearSelection();
      }
    },
    // 菜单选择
    selectionchange(selection) {
      if(selection.length==0){
        this.dialog_menuData = {};
        this.btn_add_disabled = false;
        this.btn_edit_disabled = true;
      }else if(selection.length==1){
        this.dialog_menuData = selection[0];
        this.btn_add_disabled = false;
        this.btn_edit_disabled = false;
      }else {
        this.btn_add_disabled = true;
        this.btn_edit_disabled = true;
      }
    },
    // 获取上级菜单列表
    remoteMethod(query){
      api.system_menu_data({menu_title:query}).then(res=> {
        this.parent_menu_list = res.data
      })
    },
    // 获取菜单列表
    get_system_menu() {
      this.loading_table = true
      api.system_menu().then(res=> {
        this.loading_table = false
        this.tableData = res.data
        // this.tableData_now = this.tableData
        // this.tableData_now = this.tableData
        this.search_tableData_now();
      })
    },
    // 搜索按钮
    search_tableData_now() {

      const listFilter = (list) => {
        let new_list = []
        list.forEach(e => {
          let temp_TF = true;
          if(this.search_param.menu_title !== ''){
            temp_TF = e.menu_title.includes(this.search_param.menu_title)
          }
          if(temp_TF&&this.search_param.visible!==''){
            temp_TF = e.visible === this.search_param.visible
          }
          if(temp_TF){
            new_list.push(e);
            return; 
          }
          if(e.children.length>0){
            e.children = listFilter(e.children)
            if(e.children.length>0) {
              new_list.push(e)
            }
          }
        });
        return new_list;
      }
      let tempList = JSON.parse(JSON.stringify(this.tableData))
      this.tableData_now = listFilter(tempList)
    },
    
    
    // 新增菜单项
    add_system_menu() {
      if (this.testParams(this.menuData,['menu_type','menu_title','web_path',],true) == false) {
        return
      }
      this.loadingShow('新增中')
      this.menuData.priority?this.menuData.priority = Number(this.menuData.priority):''
      
      api.system_menu_add(this.menuData).then(res=> {
        this.close_menu_dialog(true);
        this.$message.success('新增成功')
        this.get_system_menu();
      }).finally(msg => {
        this.loading.close();
      })
    },
    // 修改菜单
    put_system_menu() {
      if (this.testParams(this.menuData,['menu_type','menu_title','web_path',]) == false) {
        return
      }
      this.loadingShow('修改中')
      this.menuData.priority?this.menuData.priority = Number(this.menuData.priority):''
      api.system_menu_put(this.menuData).then(res=> {
        this.close_menu_dialog(true);
        this.$message.success('修改成功')
        this.get_system_menu();
      }).finally(msg => {
        this.loading.close();
      })
    },
    // 删除菜单项
    del_system_menu(row) {
      const loading = this.$loading({
        lock: true,
        text: '删除中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      api.system_menu_delete({menu_id_list:[row.menu_id]}).then(res=> {
        this.close_menu_dialog(true);
        this.$message.success('删除成功')
        this.get_system_menu();
      }).finally(msg => {
        loading.close();
      })
    },
    // 关闭菜单弹窗open_addMenu_dialog
    close_menu_dialog(clean=false) {
      if(clean){
        this.menuData = {
          parent_menu_id: '',
          menu_title: '',
          menu_type: '',
          web_path: '',
          permission_flag: '',
          priority: '',
          visible: true,
        };
      }
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.menuManage {
  margin: 10px 20px;
}
.box {
  background-color: #fff;
  padding: px(10);
  border-radius: px(8);
}
.top {
  ul {
    display: flex;
    align-items: center;
    justify-content: start;
    li {
      width: 25%;
      align-items: center;
      display: flex;
      &>span {
        flex: 1;
      }
      &>p {
        flex: 3;
      }
    }
  }
}
.body {
  margin-top: px(10);
  
}

.type-box {
  border-radius: px(8);
  padding: px(5) px(15);
  color: #fff;
}
.show-box {
  border-radius: px(18);
  padding: px(5) px(15);
  color: #fff;
}
.dialog-body {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      width: 80%;
      display: flex;
      margin-bottom: px(10);
      align-items: center;
      span {
        width: px(130);
        text-align: right;
        margin-right: px(10);
        i {
          color: red;
          margin-right: px(5);
        }
      }
    }
  }
  
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>